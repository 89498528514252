import React, { useState } from 'react'
import { IoChevronDown } from "react-icons/io5";
import './home.css'

export default function FAQ() {
    const faqData = [
        {
            question: "What does Tetherform do?",
            answer: "Tetherform is a survey tool that automatically transforms responses into insights. It helps professionals collect feedback, analyze it quickly, and make data-driven decisions without spending hours on spreadsheets."
        },
        {
            question: "Who uses Tetherform?",
            answer: "Anyone seeking an escape from heavy data analysis for surveys. This includes Human Resources teams, corporate event planners, market researchers and restaurants."
        },
        {
            question: "How is Tetherform different from other survey tools?",
            answer: "Tetherform stands out by offering automatic analysis of survey responses. Instead of just collecting data, it provides insights and suggestions, saving you time and helping you make informed decisions faster."
        },
        {
            question: "Is my data secure with Tetherform?",
            answer: "Yes, data security is our top priority. Tetherform encrypts all data, even at rest. Survey responses are fully anonymous by design for additional privacy."
        },
        {
            question: "How does Tetherform's pricing model work?",
            answer: "Tetherform's pricing is primarily based on the number of monthly responses you need. Most plans include unlimited forms, advanced analytics, and automatic inbox delivery. Choose the plan that fits your needs, and upgrade or downgrade at any time."
        }
    ];
    return (
        <div className="home-faq-section">
            <h2>Frequently Asked Questions</h2>
            <div className="home-faq-list">
                {faqData.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    )
}

function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="faq-item">
            <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
                <span>{question}</span>
                <IoChevronDown className={`faq-icon ${isOpen ? 'open' : ''}`} />
            </div>
            <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
                {answer}
            </div>
        </div>
    );
}