import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganization } from '../../context/OrganizationContext';
import { getOrganizationImports, getAccountManagementUrl } from '../../api';
import './OrgImports.css';
import '../forms_list/OrgForms.css';
import { FaSearch } from 'react-icons/fa';
import { FaPlus } from "react-icons/fa6";
import { IoMdAnalytics } from "react-icons/io";

import Loader from '../loaders/Loader';
import { formatDistanceToNow } from 'date-fns';

export default function OrgImports() {
    const navigate = useNavigate();
    const { organization, organizationIsLoading, isActiveLicense } = useOrganization();
    const [imports, setImports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [licenseAllowsAnalytics, setLicenseAllowsAnalytics] = useState(() => { return false })
    const [isManagingPlan, setIsManagingPlan] = useState(() => { return false })

    useEffect(() => {
        const fetchImports = async () => {
            try {
                if (organization?._id) {
                    const response = await getOrganizationImports(organization._id);
                    setImports(response.data);
                }
                setLoading(false);
            } catch (error) {
                console.log('Failed to fetch imports:', error);
                setLoading(false);
            }
        };

        if (organization) {
            fetchImports();
        }
    }, [organization]);

    const handleNewImport = () => {
        if (!licenseAllowsAnalytics) return
        if (organization?._id) {
            navigate(`/o/${organization._id}/imports/new`);
        }
    };

    const handleImportClick = (importId) => {
        if (organization?._id) {
            navigate(`/o/${organization._id}/imports/${importId}`);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const sortImports = (imports) => {
        return [...imports].sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
    };

    const filteredAndSortedImports = sortImports(
        imports.filter(imp => !searchQuery || imp.dataset_name.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    const handleManagePlan = async () => {
        try {
            if (!organization) return
            let organizationId = organization._id
            if (!organizationId) return
            if (isManagingPlan) return
            setIsManagingPlan(true)
            const response = await getAccountManagementUrl(organizationId)
            if (response.data && response.data.checkoutSessionUrl) {
                window.location.href = response.data.checkoutSessionUrl
                setIsManagingPlan(false)
            }
        } catch (err) {
            return setIsManagingPlan(false)
        } finally {
            setIsManagingPlan(false)
        }
    }
    useEffect(() => {
        document.title = "Analyze | Tetherform";
    }, []);
    useEffect(() => {
        if (organization) {
            if (organization.subscription_benefits) {
                if (organization.subscription_benefits.analytics) {
                    setLicenseAllowsAnalytics(true)
                }
            }
        }
    }, [organization])
    return (
        <div className="org-imports-container">
            {organization && !organizationIsLoading && 
                <div className="organization-imports">
                    <div className="org-imports-main">
                        {imports.length > 0 &&
                        <div className="org-imports-header">
                            <div className="org-imports-header-titles">
                                <div>
                                    <div className="org-imports-header-titles-page-title">
                                        <IoMdAnalytics />
                                        <h2>Analyze</h2>
                                    </div>
                                    <div className="org-imports-header-subtitle">Analyze external survey data with Tetherform to get insights and recommendations in minutes</div>
                                </div>
                                
                                <div className="header-buttons">
                                {licenseAllowsAnalytics &&
                                    <button className="new-import-button" onClick={handleNewImport}>
                                        <FaPlus className="org-imports-icon" />
                                        <span className="org-imports-new-button-text">Import Data</span>
                                    </button>
                                    }
                                </div>
                                
                            </div>
                            {licenseAllowsAnalytics &&
                            <div className="org-imports-filters">
                                <div className="org-imports-search">
                                    <FaSearch className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search imports..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="org-imports-search-input"
                                    />
                                </div>
                            </div>
                            }
                        </div>
                        }

                        {isActiveLicense && imports.length === 0 && !loading ? (
                            <div className="org-imports-empty-state">
                                <div className="org-imports-empty-state-content">
                                    <h1>Import Your Survey Data</h1>
                                    <p>Start by importing your existing survey data to analyze responses and gain insights.</p>
                                    
                                    <div className="org-imports-empty-state-steps">
                                        <div className="org-imports-empty-step">
                                            <div className="org-imports-empty-step-number">1</div>
                                            <div className="org-imports-empty-step-content">
                                                <h3>Upload Your File</h3>
                                                <p>Select your CSV or Excel file containing survey responses</p>
                                            </div>
                                        </div>
                                        <div className="org-imports-empty-step">
                                            <div className="org-imports-empty-step-number">2</div>
                                            <div className="org-imports-empty-step-content">
                                                <h3>Map Your Columns</h3>
                                                <p>Tell us which columns contain questions, answers, and other data</p>
                                            </div>
                                        </div>
                                        <div className="org-imports-empty-step">
                                            <div className="org-imports-empty-step-number">3</div>
                                            <div className="org-imports-empty-step-content">
                                                <h3>Get Insights</h3>
                                                <p>View analytics and recommendations based on your survey data</p>
                                            </div>
                                        </div>
                                    </div>

                                    <button 
                                        className="org-imports-empty-state-button" 
                                        onClick={handleNewImport}
                                    >
                                        <FaPlus className="org-imports-icon" />
                                        Start Your First Import
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="org-imports-list">
                                {filteredAndSortedImports.map((importItem) => (
                                    <div
                                        key={importItem._id}
                                        className="org-forms-item"
                                    >
                                        <div className="org-forms-item-header">
                                            <div className="org-forms-item-title-section">
                                                <span className={`form-status badge-${importItem.status}`}>
                                                    {importItem.status.charAt(0).toUpperCase() + importItem.status.slice(1)}
                                                </span>
                                                <h3 className="form-title">{importItem.dataset_name}</h3>
                                                <div className="form-subtitle">
                                                    {importItem.description ? importItem.description : "No description"}
                                                </div>
                                            </div>
                                        </div>
                                        <span className="org-forms-updated-date">
                                            {formatDistanceToNow(new Date(importItem.createdAt), { addSuffix: true })}
                                        </span>

                                        {importItem.responses > 0 && (
                                            <div className="org-forms-item-stats">
                                                <div className="org-forms-response-badge">
                                                    {importItem.responses} {importItem.total_responses === 1 ? 'response' : 'responses'}
                                                </div>
                                            </div>
                                        )}

                                        {importItem.error_message && (
                                            <div className="import-error-message">
                                                Error: {importItem.error_message}
                                            </div>
                                        )}

                                        <div className="org-forms-item-actions">
                                            <div></div>
                                            <div className="org-forms-action-buttons-right">
                                                <button 
                                                    className="org-forms-action-button org-forms-action-button-edit"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleImportClick(importItem._id);
                                                    }}
                                                >
                                                    Manage
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {(!isActiveLicense || !licenseAllowsAnalytics) &&
                        <div className="form-builder-analytics-container" style={{marginTop: "20vh"}}>
                            <div className="form-builder-analytics-upgrade">
                                <h3>Unrivaled Analytics</h3>
                                <p className="form-builder-analytics-message">Use the power of our advanced insights and analytics reports to make faster, better decisions as a team based on form responses. Available to organizations with a Standard plan or higher.</p>
                                {organization && <div className="form-builder-analytics-upgrade-button" onClick={handleManagePlan}>Manage Plan</div>}
                            </div>
                        </div>
                    }
                </div>
            }
            {(organizationIsLoading || loading) &&
                <div className="organization-imports" style={{marginTop: "20vh"}}>
                    <Loader/>
                </div>
            }
        </div>
    );
}
