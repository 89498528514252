import React, { useState, useEffect } from 'react'
import contentfulClient from '../../helpers/ContentfulApi';
import Loader from '../loaders/Loader'
import { updateHeadTags, updateSchemaOrgJSONLD, updateCanonicalTag } from '../../helpers/Helpers'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { format, parseISO } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../footer/Footer';
import { IoIosArrowUp } from 'react-icons/io';
import { FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa';
import OrgLogo from '../gizmos/OrgLogo';

export default function BlogPost() {
    let navigate = useNavigate();
    const { slug } = useParams();
    const [loaded, setLoaded] = useState(() => { return false })
    const [post, setPost] = useState(() => { return []})
    const [headings, setHeadings] = useState([]);
    const fetchPost = () => {
        if (!slug) return
        try {
            contentfulClient.getEntries({
                content_type: "blogPost",
                'fields.urlSlug': slug,
                limit: 1
            }).then((response) => {
                if (response.items.length === 0) return navigate('/blog')
                const postData = response.items[0].fields
                setPost(postData)
                setHeadings(extractHeadings(postData.content))
                setLoaded(true)
                // document.title = response.items[0].fields.blogTitle
            })
            
        } catch (error) {
            
        }
    }
    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node) => {
                const text = node.content[0].value;
                const id = text.toLowerCase()
                    .replace(/[^a-z0-9]+/g, '-')
                    .replace(/(^-|-$)/g, '');
                return `<h2 id="${id}">${text}</h2>`;
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { file, title, description } = node.data.target.fields;
                const imageUrl = file.url;
                return `<a href="${imageUrl}" target="_blank" rel="noopener noreferrer"><img src="${imageUrl}" alt="${description || title}" /></a>`;
            },
            [INLINES.HYPERLINK]: (node, next) => 
                `<a href="${node.data.uri}" target="_blank" rel="noopener noreferrer">${next(node.content)}</a>`
        }
    };
    const getProductDescription = () => {
        const defaultDescription = <p>We are a survey platform that analyzes responses for personalized recommendations and insights so you don't have to spend time looking through data.</p>
        try {
            if (!post) return ""
            let categories = ""
            if (!post.contentCategories) return defaultDescription
            categories = post.contentCategories.join(" ").toLowerCase()

            if (categories.includes("event")) return <p>Tetherform collects and analyzes post-event survey data to help planners <strong>improve the attendee experience</strong> with personalized insights and recommendations.</p>

            if (categories.includes("associations")) return <p>Tetherform helps assocations <strong>engage and retain members</strong> with surveys that help them feel seen.</p>

            return defaultDescription
        } catch (error) {
            return defaultDescription
        }
    }
    const updateMeta = () => {
        if (!post) return
        if (!post.blogTitle) return
        let title = post.blogTitle
        let metaTags = [
            { name: 'description', content: post.tagline || 'No description provided.' },
            { name: 'author', content: post.authorName || 'Tetheros' },
            { name: 'og:title', content: post.blogTitle },
            { name: 'og:description', content: post.tagline || 'No description provided.' },
            { name: 'og:type', content: 'article' },
            { name: 'og:image', content: post.heroImage ? post.heroImage.fields.file.url : 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
            { name: 'og:url', content: `https://www.tetherform.com/blog/${post.urlSlug}` },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:title', content: post.blogTitle },
            { name: 'twitter:site', content: "@tetheros" },
            { name: 'twitter:url', content: `https://www.tetherform.com/blog/${post.urlSlug}` },
            { name: 'twitter:description', content: post.tagline || 'No description provided.' },
            { name: 'twitter:image', content: post.heroImage ? post.heroImage.fields.file.url : 'https://storage.googleapis.com/tetheros-public/tetherform_landing/og-image.jpg' },
            { name: 'twitter:image:alt', content: post.heroImage.fields.description ? post.heroImage.fields.description : 'No alt text provided' }
        ]
        const structuredData = {
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "headline": post.blogTitle,
            "image": post.heroImage ? post.heroImage.fields.file.url : undefined,
            "author": {
              "@type": "Person",
              "name": post.authorName
            },
            "publisher": {
              "@type": "Organization",
              "name": "Tetherform",
              "logo": {
                "@type": "ImageObject",
                "url": "https://storage.googleapis.com/tetheros-public/tetherform_landing/logo-tetherform-small.png"
              }
            },
            "datePublished": post.published,
            "dateModified": post.updatedAt || post.published,
            "description": post.tagline
        };
        // Add canonical tag dynamically
        const canonicalUrl = `https://www.tetherform.com/blog/${post.urlSlug}`;
        updateCanonicalTag(canonicalUrl);
        updateHeadTags(title, metaTags)
        updateSchemaOrgJSONLD(structuredData)
    }
    useEffect(() => {
        fetchPost()
    // eslint-disable-next-line
    }, [slug])
    useEffect(() => {
        window.scrollTo(0, 0)
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateMeta()
    // eslint-disable-next-line
    }, [post])
    return (
        <div>
            <div className="blog-post-page-container">
                <div className="blog-post-left-sidebar">
                    <TableOfContents headings={headings} />
                </div>
                {loaded &&
                <div className="blog-post">
                    <div className="blog-post-header-navigation">
                        <span onClick={() => navigate('/blog')}>Blog</span>
                        <span style={{cursor: "default"}}>{"\\\\"}</span>
                        <span onClick={() => navigate('/blog')}>{post.contentCategories[0]}</span>
                    </div>
                    <h1>{post.blogTitle}</h1>
                    <div className="blog-post-tagline">{post.tagline}</div>
                    <div className="blog-post-metas">
                        <div className="blog-post-meta-container">
                            <label>Published</label>
                            <p>{format(parseISO(post.published), 'PP')}</p>
                        </div>
                        <div className="blog-post-meta-container">
                            <label>Written by</label>
                            <p>{post.authorName}</p>
                        </div>
                    </div>
                    <div className="blog-post-content" dangerouslySetInnerHTML={{__html: documentToHtmlString(post.content, options)}}></div>
                    <SocialShare post={post} />
                    <RecentPosts currentSlug={post.urlSlug} navigate={navigate} />
                    <BlogPostFooter navigate={navigate}/>
                </div>
                }
                {!loaded &&
                <div className="blog-post">
                    <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "200px", minHeight: "60vh"}}>
                        <Loader/>
                    </div>
                </div>
                }
                <div className="blog-post-right-sidebar">
                    <OrgLogo  logoUrl="https://storage.googleapis.com/tetheros-public/tetherform_landing/logo512.png" organizationName="Tetherform" heightSizePixels={80} widthSizePixels={80} />
                    <div className="blog-sidebar-about">
                        <div>Tetherform</div>
                        <p>Welcome to our blog!</p>
                        {getProductDescription()}
                        <p>Spend more time making decisions that matter.</p>
                        <p><a href="https://tetherform.com">Click here to learn more</a></p>
                    </div>
                </div>
                <BackToTop />
            </div>
            <Footer/>
            
        </div>
    )
}


function BlogPostFooter({navigate}) {
    return (
        <div className="blog-post-footer">
            <div className="blog-post-footer-cta">
                <p>Power your association with survey insights that drive engagement, retention, and impact.</p>
                <div onClick={() => navigate('/register')}>Start for free</div>
            </div>
            <div style={{cursor: "pointer", color: "#2a73ae", textDecoration: "underline", fontSize: "14px", fontWeight: 500}} onClick={() => navigate('/blog')}>Return to library</div>
        </div>

    )
}

function TableOfContents({ headings }) {
    const [activeId, setActiveId] = useState('');

    useEffect(() => {
        if (!headings.length) return;

        const observer = new IntersectionObserver(
            (entries) => {
                // Get all entries that are currently intersecting
                const visibleEntries = entries.filter(entry => entry.isIntersecting);
                
                // If we have visible entries, get the last one (closest to bottom)
                if (visibleEntries.length > 0) {
                    const lastVisible = visibleEntries[visibleEntries.length - 1];
                    setActiveId(lastVisible.target.id);
                }
            },
            {
                rootMargin: '-20% 0px -60% 0px', // Adjust these values to change when sections become "active"
                threshold: 0
            }
        );

        // Observe all h2 elements
        headings.forEach(heading => {
            const element = document.getElementById(heading.id);
            if (element) observer.observe(element);
        });

        return () => {
            headings.forEach(heading => {
                const element = document.getElementById(heading.id);
                if (element) observer.unobserve(element);
            });
        };
    }, [headings]);

    if (!headings.length) return null;
    
    return (
        <div className="blog-post-toc">
            <h3>Contents</h3>
            <ul>
                {headings.map((heading, index) => (
                    <li key={index}>
                        <a 
                            href={`#${heading.id}`}
                            className={heading.id === activeId ? 'active' : ''}
                        >
                            {heading.text}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function extractHeadings(content) {
    if (!content || !content.content) return [];
    
    return content.content.reduce((acc, node) => {
        if (node.nodeType === BLOCKS.HEADING_2) {
            const text = node.content[0].value;
            // Create a URL-friendly ID from the heading text
            const id = text.toLowerCase()
                .replace(/[^a-z0-9]+/g, '-')
                .replace(/(^-|-$)/g, '');
            acc.push({ id, text });
        }
        return acc;
    }, []);
}

function BackToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <div className="back-to-top" onClick={scrollToTop} role="button" aria-label="Back to top">
                    <IoIosArrowUp size={24} />
                </div>
            )}
        </>
    );
}

function SocialShare({ post }) {
    const shareUrl = `https://www.tetherform.com/blog/${post.urlSlug}`;
    const title = post.blogTitle;

    const shareLinks = {
        twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`
    };

    return (
        <div className="blog-post-social">
            <div className="blog-post-social-label">Share this article</div>
            <div className="blog-post-social-links">
                <a 
                    href={shareLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-post-social-link"
                    aria-label="Share on Twitter"
                >
                    <FaTwitter size={20} />
                </a>
                <a 
                    href={shareLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-post-social-link"
                    aria-label="Share on LinkedIn"
                >
                    <FaLinkedin size={20} />
                </a>
                <a 
                    href={shareLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-post-social-link"
                    aria-label="Share on Facebook"
                >
                    <FaFacebook size={20} />
                </a>
            </div>
        </div>
    );
}

function RecentPosts({ currentSlug, navigate }) {
    const [recentPosts, setRecentPosts] = useState([]);

    const fetchRecentPosts = async () => {
        try {
            const response = await contentfulClient.getEntries({
                content_type: "blogPost",
                order: "-sys.createdAt",
                limit: 4 // Fetch 4 to account for current post
            });

            // Filter out the current post and limit to 3 posts
            const filteredPosts = response.items
                .map(item => item.fields)
                .filter(post => post.urlSlug !== currentSlug)
                .slice(0, 3);

            setRecentPosts(filteredPosts);
        } catch (error) {
            console.error('Error fetching recent posts:', error);
        }
    };

    const handlePostClick = (e, postSlug) => {
        if (!e.ctrlKey && !e.metaKey && e.button !== 1) {
            e.preventDefault();
            window.scrollTo(0, 0);
            navigate(`/blog/${postSlug}`);
        }
    };

    useEffect(() => {
        fetchRecentPosts();
    // eslint-disable-next-line
    }, [currentSlug]);

    if (recentPosts.length === 0) return null;

    return (
        <div className="blog-post-recent">
            <h3>Recent Posts</h3>
            <div className="blog-post-recent-grid">
                {recentPosts.map((post) => (
                    <article key={post.urlSlug} className="blog-post-recent-item">
                        <a 
                            href={`/blog/${post.urlSlug}`}
                            onClick={(e) => handlePostClick(e, post.urlSlug)}
                            className="blog-post-recent-image"
                        >
                            <img 
                                src={post.heroImage.fields.file.url} 
                                alt={post.heroImage.fields.description || post.blogTitle} 
                            />
                        </a>
                        <div className="blog-post-recent-content">
                            <div className="blog-post-recent-categories">
                                {post.contentCategories.map((category) => (
                                    <span key={category}>{category}</span>
                                ))}
                            </div>
                            <a 
                                href={`/blog/${post.urlSlug}`}
                                onClick={(e) => handlePostClick(e, post.urlSlug)}
                                className="blog-post-recent-title"
                            >
                                <h4>{post.blogTitle}</h4>
                            </a>
                        </div>
                    </article>
                ))}
            </div>
        </div>
    );
}